.ag-header-cell-label {
    white-space: normal;
    justify-content: center !important;
    align-items: center;
    display: flex;
    height: 100%;
  }

  .ag-cell {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    white-space: normal !important;
    line-height: 1.5 !important;
  }