
* {
  font-family: Arial, Helvetica, sans-serif;
}

html, body {
  margin: 0;
  border: 0;
  padding: 0;
  background-color: #fff;
}

main {
  margin: auto;
  width: 50%;
  padding: 20px;
}

main > h1 {
  text-align: center;
  font-size: 3.5em;
}


form {
  max-width: 400px;
  margin: 0 auto;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

#preloader {
  display: none;
  text-align: center;
}

#preloader img {
  width: 50px;
  height: 50px;
}


.footer--logo {
  max-width: 30%;
}

.entry-title a {
  /*  pointer-events: none !important;*/
}

.jobs-template-default .post--pagination .row .grid-item.index--prev a {
  background: rgb(0 0 0 / 50%);
}

.jobs-template-default .post--pagination .row {
  display: none !important;
}

.footer--logo a:nth-child(2) {
  display: none !important;
}

.page-id-117 .entry-content p,
.entry-content ul {
  display: none !important;
}

.jobs-template-default .post--hero,
.jobs-template-default .post--pagination .row .grid-item .background--image {
  /* background-image: url(/wp-content/uploads/2021/02/Cybersecurity-Why_310133665.jpg) !important; */
  background-position: center center !important;
  background-repeat: no-repeat;
}

.page-id-117 .entry-content p:first-child {
  display: block !important;
  max-height: 99px;
  overflow: hidden;
}

.jobs-template-default .post--content {
  padding: 5rem 10rem !important;
}

.footer--logo>a>img {
  min-width: 300px;
}

h4.official {
  font-size: 1rem !important;
  opacity: .6;
}

.logo-container {
  display: flex;
  grid-template-columns: 50% 50%;
  align-items: center;
  justify-content: center;
}

.logo-container>.logo {
  padding: 20px;
}

li#menu-item-32 ul.sub-menu {
  margin-top: -5px !important;
}

header .top--bar .product--search-wrap .product--search .form--submit span {
  /* display: none;*/
}

@media (max-width:1330px) and (min-width:1000px) {
  header .main--menu li.megamenu:hover .sub-menu {
    left: 0 !important;
    min-width: unset !important;
    max-width: unset !important;
    width: 100% !important;
  }

  header .main--menu li#menu-item-30.megamenu:hover .sub-menu,
  li#menu-item-6927 ul.sub-menu {
    margin: 0 !important;
  }

  header .main--menu li.megamenu .sub-menu,
  header .main--menu li.megamenu .sub-menu li {
    max-width: 100% !important;
    width: 100% !important;
  }

}

@media (max-width:500px) {
  .jobs-template-default .post--content {
    padding: 5rem !important;
  }

  header .top--bar .product--search-wrap .product--search .form--submit span {
    display: none;
  }

  .block--hero .hero--banner .banner--content.featured--content ul li:first-child * {
    max-width: 100% !important;
  }

}


.row {
  display: flex !important;
}

.column {
  flex: 25% !important;
  /* Each column now takes up 25% of the row's width */
  padding: 10px !important;
}

/* Optional styling for better presentation */
.column p,
.column a {
  margin: 0 !important;
  /* Removes default margin */
}

.column strong {
  display: block !important;
  /* Makes strong tag display in block for better control */
}