:root {
    --primary-color: #0033A0; /* ITsavvy Blue */
    --secondary-color: #ffffff; /* White */
    --accent-color: #cccccc; /* Grey */
    --font-primary: 'Segoe UI', Arial, sans-serif;
}

body {
    margin: 0;
    font-family: var(--font-primary);
    background-color: var(--secondary-color);
    color: #333333;
}

.nav-bar {
    background-color: var(--primary-color);
    color: var(--secondary-color);
}

.button {
    background-color: var(--primary-color);
    color: var(--secondary-color);
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.button:hover {
    background-color: darken(var(--primary-color), 10%);
}

.input-field {
    border: 2px solid var(--accent-color);
    border-radius: 5px;
    padding: 10px;
    width: calc(100% - 22px); /* padding and border */
}
